import React, { useState } from "react";
import Input from "./Input/Input";
import Dropdown from "../../InvestmentPlan/AllPlans/Dropdown/Dropdown";

import { toast } from "react-toastify";
import Cookies from "js-cookie";
import axios from "axios";
import styles from "./TransferBalance.module.css";
const TransferBalance = ({ setPopup, userInfo, planDetails, getUserInfo, isInsured }) => {
  const [values, setValues] = useState({
    availableFunds: userInfo.balance, //current Funds
    amountToTransfer: planDetails.min_amount / Math.pow(10, 8), //min amount
  });
  const [selectedDuration, setSelectedDuration] = useState(planDetails.possible_durations[0]);
  const [activeDropDownItem, setActiveDropdownItem] = useState(0);

  async function handleSubmit(e) {
    e.preventDefault();

    if (values.amountToTransfer > values.availableFunds) {
      toast.error("You don't have enough funds to transfer.");
      return;
    }
    if (values.amountToTransfer < planDetails.min_amount / Math.pow(10, 8)) {
      toast.error(
        "Minimum Investment amount is " +
        planDetails.min_amount / Math.pow(10, 8) +
        " USD"
      );
      return;
    }

    if (planDetails.max_amount > 0) {
      if (values.amountToTransfer > planDetails.max_amount / Math.pow(10, 8)) {
        toast.error(
          "Maximum Investment amount is " +
          planDetails.max_amount / Math.pow(10, 8) +
          " USD"
        );
        return;
      }
    }

    let url = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");

    let body = {
      amount: Number(values.amountToTransfer),
      plan_id: planDetails.id,
      payment_method: "Balance",
      duration: Number(selectedDuration),
      is_insured: isInsured
    };

    console.log(body, "body");

    let { data, status } = await axios.post(
      url + "/user/create-investment",
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
          Authorization: "Bearer " + user_id,
        },
      }
    );

    if (status === 200) {
      toast.success("Investment created successfully to " + planDetails.name);
      setPopup(false);
      getUserInfo();
    }
  }
  const inputs = [
    {
      label: "Available Balance",
      type: "number",
      name: "availableFunds",
      text: "Pending or locked balance is not included in available balance.",
      disabled: true,
    },
    {
      label: "Investment Amount",
      type: "number",
      name: "amountToTransfer",
      placeholder: "Enter Amount",
      text: "The amount you want to transfer into main account.",
      disabled: false,
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  let contructedDropdownItems = [];
  console.log(planDetails, "planDetails")
  if (!isInsured) {
    for (let i = 0; i < planDetails.possible_durations.length; i++) {
      contructedDropdownItems.push(
        planDetails.possible_durations[i] + " Days - " + planDetails.possible_interest_rates[i] + "%"
      );
    }
  }

  if (isInsured) {
    for (let i = 0; i < planDetails.possible_durations.length; i++) {
      contructedDropdownItems.push(
        planDetails.possible_durations[i] + " Days - " + planDetails.possible_interest_rates_for_insurance[i] + "%"
      );
    }
  }

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>
              {String(planDetails.name).toLocaleUpperCase()} Investment
            </h2>
            <p className={styles.text}>
              {/* You can transfer Investment account balance to main account. */}
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <Dropdown
              dropdownItems={contructedDropdownItems}
              active={setActiveDropdownItem}
              setActive={setActiveDropdownItem}
              setSelectedDuration={setSelectedDuration}
            />
            <div className={styles.buttonContainer}>
              <button
                onClick={handleSubmit}
                className={[styles.button, styles.transferButton].join(" ")}
              >
              Submit
              </button>
              <button className={styles.button} onClick={() => setPopup(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className={styles.suggestionContainer}>
          <p className={styles.suggestion}>
            The amount will immediately transfer into your main account so you
            can withdraw funds or re-investment.
          </p>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};

export const WithdrawBalance = ({ setPopup, userInfo }) => {
  const [values, setValues] = useState({
    // availableFunds: userInfo.balance, //current Funds
    availableFunds: userInfo.balance,
    amountToTransfer: 3,
    // amountToTransfer: planDetails.min_amount / Math.pow(10, 8), //min amount
  });

  const inputs = [
    {
      label: "Available Balance",
      type: "number",
      name: "availableFunds",
      text: "Pending or locked balance is not included in available balance.",
      disabled: true,
      is_currency: true,
    },
    {
      label: "Withdrawal BEP20 Wallet Address",
      type: "text",
      name: "walletAddress",
      placeholder: "Enter Wallet Address",
      text: "",
      disabled: false,
      is_currency: false,
    },
    {
      label: "Withdraw Amount",
      type: "number",
      name: "amountToTransfer",
      placeholder: "Enter Amount",
      text: "",
      disabled: false,
      is_currency: true,
    },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    let api = process.env.REACT_APP_API_URL;
    let user_id = Cookies.get("auth-token");
    let body = {
      amount: Number(values.amountToTransfer),
      asset: String(localStorage.getItem("withdraw-token-id")),
      wallet_address: values.walletAddress,
    }

    let { data, status } = await axios.post(
      api + "/user/withdrawal/new",
      body,
      {
        headers: {
          "Content-Type": "application/json",
          "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
          Authorization: "Bearer " + user_id,
        },
      }
    );

    if (status === 200) {
      toast.success("Withdrawal request created successfully.");
      setPopup(false);
    }

  }

  let token = localStorage.getItem("withdraw-token");
  let tokenID = localStorage.getItem("withdraw-token-id");
  // alert(tokenID);

  return (
    <>
      <div className={styles.mainWrapper}>
        <div className={styles.wrapper}>
          <div>
            <h2 className={styles.title}>
            WITHDRAW USDT (BEP20) 
            </h2>
            <p className={styles.text}>
              {/* You can withdraw {token.toLocaleUpperCase()} from your account. */}
             {/*  */}
            </p>
          </div>
          <form
            action=""
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className={styles.form}
          >
            {inputs.map((input, i) => (
              <Input
                {...input}
                key={i}
                value={values[input.name]}
                onChange={onChange}
              />
            ))}
            <div className={styles.buttonContainer}>
              <button
                onClick={handleSubmit}
                className={[styles.button, styles.transferButton].join(" ")}
              >
                Execute
              </button>
              <button className={styles.button} onClick={() => setPopup(false)}>
                Cancel
              </button>
            </div>
          </form>
        </div>
        <div className={styles.suggestionContainer}>
          <p className={styles.suggestion}>
            {/* Amount will be transferred into your wallet address. */}
          </p>
        </div>
      </div>
      <div className={styles.overlay} onClick={() => setPopup(false)}></div>
    </>
  );
};

export default TransferBalance;
