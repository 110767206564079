import React from "react";
import styles from "./Heading.module.css";

const Heading = () => {
  return (
    <div className={styles.headingContainer}>
      <h2 className={styles.heading}>Investment Plans</h2>
      <p className={styles.text}>
        {"Get started with any of our investment plans".toUpperCase()}
      </p>
      {/*  <p className={styles.tagline}>
        Staken Sie Ihre Kryptowährungen und profitieren Sie von unseren
        automatisierten Investitionsstrategien.
      </p> */}
    </div>
  );
};

export const InsuranceHeading = () => {
  return (
    <div className={styles.headingContainer}>
      <h2 className={styles.heading}>Insurance Plans</h2>
      <p className={styles.text}>
        Our comprehensive Investment plans tailored to meet your growth needs.
      </p>
      {/*  <p className={styles.tagline}>
        Staken Sie Ihre Kryptowährungen und profitieren Sie von unseren
        automatisierten Investitionsstrategien.
      </p> */}
    </div>
  );
};

export default Heading;
