import React from "react";
import formatNumber from "../../utils";
import styles from "./AmountInvested.module.css";
import { history, transferCard } from "../../../images/image";
import ChartComponent from "./Chart/Chart";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const AmountInvested = ({ userInfo }) => {
  let ref = "https://" + process.env.REACT_APP_DOMAIN + "/register/" + localStorage.getItem("evm_wallet");

  const navigate = useNavigate();
  return (
    <div className={styles.amountInvested}>
      <div className={styles.wrapper}>
        <div>
          <h4 className={styles.title}>Total Profits</h4>
          <div className={styles.amountContainer}>
            <div>
              <h4 className={styles.investedAmount}>
                {userInfo?.total_returns ? userInfo?.total_returns / Math.pow(10, 8) : "0"}{" "}
                <span className={styles.currency}>USD</span>{" "}
              </h4>
              <p className={styles.text}>Total Returns</p>
            </div>
            {/* <div>
              <h4 className={styles.prifitAmount}>+{157.5} </h4>
              <p className={styles.text}>Withdrawn</p>
            </div> */}
          </div>
        </div>{" "}
        <ChartComponent />
      </div>

      <div className={styles.bottomBar}>
        {/* <div className={styles.box}>
          <img
            onClick={() => {
              navigate("/history");
            }}
            src={transferCard} alt="#" className={styles.icon} />
          <p
            onClick={() => {
              navigate("/history");
            }}
            className={styles.bottomText}>Investment History</p>
        </div> */}
        <div className={styles.box}>
          <img
            onClick={() => {
              navigate("/history");
            }}
            src={history} alt="#" className={styles.icon} />
          <p
            onClick={() => {
              navigator.clipboard.writeText(ref);
              setTimeout(() => {
                toast.success("Copied to clipboard");
              }, 300);
            }}
            className={styles.bottomText}>Copy Referral Link (Each Referral Gets 5% Reward)</p>
        </div>
      </div>
    </div>
  );
};

export default AmountInvested;
